var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.p.pedidos_dependentes[0])?_c('showPedidosDependentes',{attrs:{"pedidos":_vm.p.pedidos_dependentes}}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-stepper',{attrs:{"vertical":"","elevation":"6"},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('br'),(
            _vm.temPermissaoConsultarHistorico() &&
            !(
              _vm.p.objeto.acao === 'Criação' &&
              (_vm.p.estado === 'Submetido' || _vm.p.estado === 'Ressubmetido')
            )
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"white"},on:{"click":function($event){return _vm.verHistorico()}}},on),[_vm._v(" history ")])]}}],null,false,2323882188)},[_c('span',[_vm._v("Ver histórico de alterações...")])]):_vm._e(),_c('v-stepper-step',{key:1,attrs:{"color":"amber accent-3","step":1,"editable":""}},[_c('font',{attrs:{"size":"4"}},[_c('b',[_vm._v("Informação Geral")])])],1),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('InformacaoGeral',{attrs:{"RADA":_vm.p.objeto.dados}})],1),_c('v-stepper-step',{key:2,attrs:{"color":"amber accent-3","step":2,"editable":""}},[_c('font',{attrs:{"size":"4"}},[_c('b',[_vm._v("Relatório Expositivo")])])],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('RelatorioExpositivo',{attrs:{"RE":_vm.p.objeto.dados.RE}})],1),_c('v-stepper-step',{key:3,attrs:{"color":"amber accent-3","step":3,"editable":""}},[_c('font',{attrs:{"size":"4"}},[_c('b',[_vm._v("Tabela de Seleção")])])],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('TSRada',{attrs:{"TS":_vm.p.objeto.dados.tsRada}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }